<template>
    <div>
    <b-row>
        <b-col
        md="12"
        xl="12"
        class="mb-1"
        >   

        <!-- basic -->
        <b-form-group
            label="Name"
            label-for="basicInput"
        >
            <b-form-input
            id="formName"
            placeholder="Enter Form Name"
            v-model="form.name"
            />
        </b-form-group>
        </b-col>
    </b-row>

    <h5> Add Section </h5>

    <b-row class="mb-1">
        <b-col md="12" xl="6">
            <b-form-input
                v-model="section"
                type="text"
            />
        </b-col>
        <b-col md="12" xl="6">
            <b-button
                variant="primary"
                @click="addSection()"
                >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                />
                <span>Add Section</span>
            </b-button>
        </b-col>
    </b-row>

    <!-- <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="formSubmitted"
      ref="formWizard"
    > -->

    <draggable
        v-model="form.pages"
        tag="ul"
        class="nav nav-tabs"
    >
        <div class="nav-item" v-for="(tab, index) in form.pages" :key="index">
            <a v-if="!tab.edit" class="nav-link" :id="`tab_${index}`" :class="isActive(index)" @click="selectTab(tab, index)">
                {{tab.title}}
                <feather-icon
                    icon="EditIcon"
                    size="20"
                    class="text-primary editTab"
                    @click="editTab(tab)"
                />
            </a>
            <b-form-input v-else
                v-model="form.pages[index].title"
                v-on:keyup.enter="doneEditing(tab)"
                :autofocus="true"
            />
        </div>
    </draggable> 

    <!-- <draggable
      v-model="form.pages"
      tag="ul"
    > -->
    <b-tabs ref="tabs" v-model="tabIndex">
        <b-tab
            v-for="(page, pageIndex) in form.pages"
            :key="pageIndex"
            :title="page.title"
            title-link-class="default-tab-navigation"
        >
            <b-row>
                <b-col
                md="12"
                xl="12"
                class="mb-1"
                >
                    <h1 v-if="page.title">{{page.title}}</h1>
                    <!-- page {{pageIndex}} -->

                </b-col>
            </b-row>
            
            About
            <b-form-input
                v-model="page.about"
                type="text"
            />
            <!-- <ol> -->
            <draggable
                v-model="page.questions"
                tag="ol"
            >
                
                <div 
                    v-for="(question, questionIndex) in page.questions"
                    :key="question.key"
                    class="questions-section"
                    
                >
                    <!-- {{ question.useRecomendedAnswser }} {{ question.recommendedAnswer }} -->
                    <li class="question-list">
                        <div class="edit-question">
                            <b-button-group>
                                <b-button
                                    variant="outline-warning"
                                    @click="editQuestion(questionIndex)"
                                >
                                    Edit
                                </b-button>
                                <b-button
                                    variant="outline-danger"
                                    @click="deleteQuestion(questionIndex)"
                                >
                                    Delete
                                </b-button>
                            </b-button-group>
                        </div>

                        <b-row v-if="question.type=='MultipleChoice'" class="pt-4 pb-4">
                            <!-- question -->
                            <b-col 
                            md="12"
                            xl="12"
                            >
                                {{question.question}} 
                                <feather-icon
                                    v-if="question.hint"
                                    icon="HelpCircleIcon"
                                    size="20"
                                    class="text-primary"
                                    v-b-popover.hover.top="`${question.hint}`"
                                />
                                <b-form-radio-group
                                    v-if="!question.useRecomendedAnswser"
                                    v-model="question.answer"
                                    :options="question.options"
                                    value-field="value"
                                    text-field="text"
                                    stacked
                                />
                                <b-form-radio-group
                                    v-else
                                    v-model="question.recommendedAnswer"
                                    :options="question.options"
                                    value-field="value"
                                    text-field="text"
                                    stacked
                                />
                            </b-col>
                        </b-row>
                        <b-row v-else-if="question.type=='Dropdown'" class="pt-4 pb-4">
                            <!-- question -->
                            <b-col 
                            md="12"
                            xl="12"
                            >
                            {{question.question}}
                            <feather-icon
                                v-if="question.hint"
                                icon="HelpCircleIcon"
                                size="20"
                                class="text-primary"
                                v-b-popover.hover.top="`${question.hint}`"
                            />
                            <v-select
                                v-if="!question.useRecomendedAnswser"
                                v-model="question.answer"
                                label="text"
                                :options="question.options" 
                            />
                            <v-select
                                v-else
                                v-model="question.recommendedAnswer"
                                label="text"
                                :options="question.options" 
                            />


                            </b-col>
                        </b-row>
                        <b-row v-else-if="question.type=='DropdownMultiple'" class="pt-4 pb-4">
                            <!-- question -->
                            <b-col 
                            md="12"
                            xl="12"
                            >
                            {{question.question}} ( Select all options that apply )
                            <feather-icon
                                v-if="question.hint"
                                icon="HelpCircleIcon"
                                size="20"
                                class="text-primary"
                                v-b-popover.hover.top="`${question.hint}`"
                            />
                            <v-select
                                v-if="!question.useRecomendedAnswser"
                                v-model="question.answer"
                                multiple
                                label="text"
                                :options="question.options" 
                                :closeOnSelect="false"
                            />
                            <v-select
                                v-else
                                v-model="question.recommendedAnswer"
                                multiple
                                label="text"
                                :options="question.options" 
                                :closeOnSelect="false"
                            />

                            </b-col>
                        </b-row>
                        <b-row v-else-if="question.type=='Text'" class="pt-4 pb-4">
                            <!-- question -->
                            <b-col 
                            md="12"
                            xl="12"
                            >
                            {{question.question}}
                            <feather-icon
                                v-if="question.hint"
                                icon="HelpCircleIcon"
                                size="20"
                                class="text-primary"
                                v-b-popover.hover.top="`${question.hint}`"
                            />
                            <b-form-input
                                v-model="question.answer"
                                type="text"
                                
                            />
                            </b-col>
                        </b-row>    

                        <b-row v-else-if="question.type=='Slider'" class="pt-4 pb-4">
                            <!-- question -->
                            <b-col 
                            md="12"
                            xl="12"
                            >
                            {{question.question}}
                            <feather-icon
                                v-if="question.hint"
                                icon="HelpCircleIcon"
                                size="20"
                                class="text-primary"
                                v-b-popover.hover.top="`${question.hint}`"
                            />
                                <div v-if="!question.useRecomendedAnswser" class="d-flex" >
                                    <b-form-input
                                        v-model="question.answer"
                                        type="range"
                                        number
                                        :min="question.min"
                                        :max="question.max"
                                        :step="question.step"
                                    />
                                        {{ question.answer && question.answer.toFixed(2) }}
                                </div>

                                <div v-else class="d-flex">
                                    <b-form-input
                                        v-model="question.recommendedAnswer"
                                        type="range"
                                        number
                                        :min="question.min"
                                        :max="question.max"
                                        :step="question.step"
                                    />
                                        {{ question.recommendedAnswer && question.recommendedAnswer.toFixed(2) }}
                                </div>
                            </b-col>
                            
                        </b-row>    
                        <!-- <b-form-checkbox
                            v-if="question.type!='Text'"
                            v-model="question.useRecomendedAnswser"
                            value=true
                            plain
                        >
                        Use the above answer as recommended answer
                        </b-form-checkbox> -->
                                            
                    </li>

                    <hr/>
                </div>
            <!-- </ol> -->
            </draggable>
            <div class="text-right">
                <b-button-group class="mt-1">
                    <b-button
                        variant="outline-primary"
                        @click="changeIndex(-1)"
                    >
                        Previous
                    </b-button>
                    <b-button
                        variant="outline-primary"
                        @click="changeIndex(1)"
                    >
                        Next
                    </b-button>
                </b-button-group>
            </div>  
            <div class="bottom-separation"> </div>
            
            <div class="w-100 d-flex" style="justify-content: space-between;">
                <b-button v-b-modal.addQuestion variant="primary">Add Question</b-button>
                <b-button variant="danger" @click="deleteSection">Delete Section</b-button>
            </div>
        </b-tab>
    </b-tabs>
    <!-- </draggable> -->
    <!-- </form-wizard> -->
    


  <b-modal id="addQuestion" size="lg" hide-footer hide-header centered no-close-on-backdrop no-close-on-esc >
    <div>
        <b-form-group 
            label="Question Type"
        >
            <v-select
                v-model="newQuestion"
                label="name"
                :options="questionsTypes" 
            />
        </b-form-group>
        <validation-observer ref="simpleRules">
        <b-form @submit.stop.prevent="handleSubmit">
            <b-row v-if="newQuestion && newQuestion.value=='MultipleChoice' && generateQuestion">
                    <!-- question -->
                    <b-col 
                    md="12"
                    xl="12"
                    >
                        <b-form-group
                            label="Question"
                            label-for="Question"
                        >   
                        <validation-provider
                            #default="{ errors }"
                            name="Question"
                            rules="required"
                        >
                            <b-form-input
                                v-model="generateQuestion.question"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>
                    
                    <h6 class="pl-1 small">Options</h6>
                    <div class="w-100 d-flex" v-for="(options, optionsIndex) in generateQuestion.options" :key="optionsIndex">
                        <!-- options -->
                        
                        <b-col md="8" xl="8">
                            <b-form-group
                            >
                        <validation-provider
                            #default="{ errors }"
                            name="Option"
                            rules="required"
                        >
                            <b-form-input
                                v-model="options.text"
                                type="text"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                        </b-col>

                        <b-col md="2" xl="2">
                            <b-form-group
                            >
                        <validation-provider
                            #default="{ errors }"
                            name="Option"
                            rules="required"
                        >
                            <b-form-input
                                v-model="options.value"
                                type="text"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                        </b-col>

                        <b-col md="2" xl="2">
                            <b-button
                            variant="danger"
                                @click="deleteOption(generateQuestion, optionsIndex)"
                            >
                            <feather-icon
                                icon="XIcon"
                                class="mr-25"
                            />
                            <!-- <span>Delete Option</span> -->

                        </b-button>
                        </b-col>
                    </div>
                    <div class="w-100 d-flex">
                        <b-button
                            class="ml-2 mr-2 flex-grow-1"
                            variant="primary"
                            @click="addOption(generateQuestion)"
                            >
                            <feather-icon
                                icon="PlusIcon"
                                class="mr-25"
                            />
                            <span>Add Option</span>
                        </b-button>
                    </div>
                    <!-- </b-form-group> -->
            </b-row>

            <b-row v-if="newQuestion && newQuestion.value=='Dropdown' && generateQuestion">
                    <!-- question -->
                    <b-col 
                    md="12"
                    xl="12"
                    >
                        <b-form-group
                            label="Question"
                            label-for="Question"
                        >   
                        <validation-provider
                            #default="{ errors }"
                            name="Question"
                            rules="required"
                        >
                            <b-form-input
                                v-model="generateQuestion.question"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>
                    
                    <h6 class="pl-1 small">Options</h6>
                    <div class="w-100 d-flex" v-for="(options, optionsIndex) in generateQuestion.options" :key="optionsIndex">
                        <!-- options -->
                        
                        <b-col md="8" xl="8">
                            <b-form-group
                            >
                        <validation-provider
                            #default="{ errors }"
                            name="Option"
                            rules="required"
                        >
                            <b-form-input
                                v-model="options.text"
                                type="text"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                        </b-col>
                        <b-col md="2" xl="2">
                            <b-form-group
                            >
                        <validation-provider
                            #default="{ errors }"
                            name="Option"
                            rules="required"
                        >
                            <b-form-input
                                v-model="options.value"
                                type="text"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                        </b-col>
                        <b-col md="2" xl="2">
                            <b-button
                            variant="danger"
                                @click="deleteOption(generateQuestion, optionsIndex)"
                            >
                            <feather-icon
                                icon="XIcon"
                                class="mr-25"
                            />
                            <!-- <span>Delete Option</span> -->
                        </b-button>
                        </b-col>
                    </div>
                    <div class="w-100 d-flex">
                        <b-button
                            class="ml-2 mr-2 flex-grow-1"
                            variant="primary"
                            @click="addOption(generateQuestion)"
                            >
                            <feather-icon
                                icon="PlusIcon"
                                class="mr-25"
                            />
                            <span>Add Option</span>
                        </b-button>
                    </div>
                    <!-- </b-form-group> -->
            </b-row>
            
            <b-row v-if="newQuestion && newQuestion.value=='DropdownMultiple' && generateQuestion" >
                    <!-- question -->
                    <b-col 
                    md="12"
                    xl="12"
                    >
                        <b-form-group
                            label="Question"
                            label-for="Question"
                        >   
                        <validation-provider
                            #default="{ errors }"
                            name="Question"
                            rules="required"
                        >
                            <b-form-input
                                v-model="generateQuestion.question"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>
                    
                    <h6 class="pl-1 small">Options</h6>
                    <div class="w-100 d-flex" v-for="(options, optionsIndex) in generateQuestion.options" :key="optionsIndex">
                        <!-- options -->
                        
                        <b-col md="8" xl="8">
                            <b-form-group
                            >
                        <validation-provider
                            #default="{ errors }"
                            name="Option"
                            rules="required"
                        >
                            <b-form-input
                                v-model="options.text"
                                type="text"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                        </b-col>

                        <b-col md="2" xl="2">
                            <b-form-group
                            >
                        <validation-provider
                            #default="{ errors }"
                            name="Option"
                            rules="required"
                        >
                            <b-form-input
                                v-model="options.value"
                                type="text"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                        </b-col>

                        <b-col md="2" xl="2">
                            <b-button
                            variant="danger"
                                @click="deleteOption(generateQuestion, optionsIndex)"
                            >
                            <feather-icon
                                icon="XIcon"
                                class="mr-25"
                            />
                            <!-- <span>Delete Option</span> -->

                        </b-button>
                        </b-col>
                    </div>
                    <div class="w-100 d-flex">
                        <b-button
                            class="ml-2 mr-2 flex-grow-1"
                            variant="primary"
                            @click="addOption(generateQuestion)"
                            >
                            <feather-icon
                                icon="PlusIcon"
                                class="mr-25"
                            />
                            <span>Add Option</span>
                        </b-button>
                    </div>
                    <!-- </b-form-group> -->
            </b-row>

            <b-row v-if="newQuestion && newQuestion.value=='Text' && generateQuestion">
                    <!-- question -->
                    <b-col 
                    md="12"
                    xl="12"
                    >
                        <b-form-group
                            label="Question"
                            label-for="Question"
                        >   
                        <validation-provider
                            #default="{ errors }"
                            name="Question"
                            rules="required"
                        >
                            <b-form-input
                                v-model="generateQuestion.question"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>
            </b-row>

            <b-row v-if="newQuestion && newQuestion.value=='Slider' && generateQuestion">
                    <!-- question -->
                    <b-col 
                    md="12"
                    xl="12"
                    >
                        <b-form-group
                            label="Question"
                            label-for="Question"
                        >   
                        <validation-provider
                            #default="{ errors }"
                            name="Question"
                            rules="required"
                        >
                            <b-form-input
                                v-model="generateQuestion.question"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>

                        <b-form-group
                            label="Min"
                            label-for="Min"
                        >
                        <validation-provider
                            #default="{ errors }"
                            name="Question"
                            rules="required"
                        >
                            <b-form-input
                                v-model="generateQuestion.min"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>

                        <b-form-group
                            label="Max"
                            label-for="Max"
                        >
                        <validation-provider
                            #default="{ errors }"
                            name="Question"
                            rules="required"
                        >
                            <b-form-input
                                v-model="generateQuestion.max"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>

                        <b-form-group
                            label="Step"
                            label-for="Step"
                        >
                        <validation-provider
                            #default="{ errors }"
                            name="Question"
                            rules="required"
                        >
                            <b-form-input
                                v-model="generateQuestion.step"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>
            </b-row>

            <b-row v-if="newQuestion && generateQuestion" class="mb-2 mt-2">
                    <!-- question -->
                <b-col 
                    md="12"
                    xl="12"
                >
                <span>Hint</span>
                <b-form-input
                    v-model="generateQuestion.hint"
                />
                </b-col>
            </b-row>
            

            <b-row v-if="generateQuestion.type=='MultipleChoice'" class="pb-2">
                <b-col 
                    md="12"
                    xl="12"
                >
                    <span>Recommended Answer</span>
                    <b-form-radio-group
                        v-model="generateQuestion.recommendedAnswer"
                        :options="generateQuestion.options"
                        value-field="value"
                        text-field="text"
                        stacked
                    />
                </b-col>
            </b-row>
            <b-row v-else-if="generateQuestion.type=='Dropdown'" class="pb-2">
                <b-col 
                    md="12"
                    xl="12"
                >   
                    <span>Recommended Answer</span>
                    <v-select
                        v-model="generateQuestion.recommendedAnswer"
                        label="text"
                        :options="generateQuestion.options" 
                    />
                </b-col>
            </b-row>
            <b-row v-else-if="generateQuestion.type=='DropdownMultiple'" class="pb-2">
                <b-col 
                    md="12"
                    xl="12"
                >
                    <span>Recommended Answer</span>
                    <v-select
                        v-model="generateQuestion.recommendedAnswer"
                        multiple
                        label="text"
                        :options="generateQuestion.options" 
                        :closeOnSelect="false"
                    />
                </b-col>
            </b-row>
            <b-row v-if="generateQuestion.recommendedAnswer && generateQuestion.type && generateQuestion.type!='Text'" class="pb-4">
                <b-col 
                    md="12"
                    xl="12"
                >
                    <span>Recommendation Verbiage</span>
                    
                    <b-form-group
                        
                        label-for="Recommendation verbiage"
                    >
                         <validation-provider
                            #default="{ errors }"
                            name="Recommendation verbiage"
                            rules="required"
                        > 
                            <b-form-input
                                v-model="generateQuestion.recommendedAnswerText"
                                :state="errors.length > 0 ? false:null"
                            />
                             <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider> 
                    </b-form-group>
                </b-col>
            </b-row>

            <div v-if="newQuestion" class="w-100 d-flex">
                <b-button
                    class="ml-1 mr-1 flex-grow-1"
                    variant="success"
                    @click="saveQuestion(generateQuestion)"
                    >
                    <span>Save</span>
                </b-button>

                <b-button
                    class="delete ml-1 mr-1 flex-grow-1"
                    variant="danger"
                    @click="closeModal()"
                    >
                    <span>Cancel</span>

                </b-button>
            </div>
        </b-form>
        </validation-observer>
    </div>
  </b-modal>

  <b-button variant="success" class="w-100 mt-1" @click="createQuestionnaire">Create Questionnaire</b-button>
</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {BFormInput, BRow, BCol, BFormGroup, BProgress, BButton, BFormTextarea, BForm, BFormRadioGroup, BTabs, BTab, BButtonGroup, VBPopover, BFormCheckbox } from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AxiosService from '@/Service/AxiosService'
import draggable from 'vuedraggable'

import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    BFormRadioGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BProgress,
    FormWizard,
    TabContent,
    BButton,
    vSelect, 
    BFormTextarea, 
    BForm,
    BTabs, 
    BTab,
    BButtonGroup,
    draggable,
    BFormCheckbox
  },
  directives: {
    'b-popover': VBPopover,
  },
  data(){
    return {
        formName:null,
        multipleChoiceOption:{
            text:null,
            value:null
        },
        multipleChoiceQuestionTemplate:{
            type:"MultipleChoice",
            question:null,
            options:[
                
            ],
            key:null,
            hint:null,
            recommendedAnswer:null
        },
        textQuestionTemplate:{
            type:"Text",
            question:null,
            answer:null,
            hint:null
        },
        sliderQuestionTemplate:{
            type:"Slider",
            min:1,
            max:10,
            step:0.01,
            question:null,
            answer:0,
            hint:null,
            recommendedAnswer:null
        },
        dropDownQuestionTemplate:{
            type:"Dropdown",
            question:null,
            options:[
                
            ],
            key:null,
            hint:null,
            recommendedAnswer:null
        },
        dropDownOptionsTemplate:{
            text:null,
            value:null,
        },
        tagType:[
            "Access Control",
            'Data protection',
            'Business resilience',
            'Endpoint Security',
            'Governance',
            'Network Security',
            'SAAS Security'
        ],
        questionsTypes:[
            { name:"Text",             value:"Text" },
            { name:"Dropdown",         value:"Dropdown" },
            { name:"DropdownMultiple", value:"DropdownMultiple" },
            { name:"MultipleChoice",   value:"MultipleChoice" },
            { name:"Slider",           value:"Slider" }
        ],
        form:{
            name:null,
            pages:[
                { title:"BasicDetails",        questions:[], sum:0, edit:false },
                { title:"Access Control",      questions:[], sum:0, edit:false },
                { title:"data protection",     questions:[], sum:0, edit:false },
                { title:"Business resilience", questions:[], sum:0, edit:false },
                {  title:"Endpoint Security",   questions:[], sum:0, edit:false },
                { title:"Governance",          questions:[], sum:0, edit:false },
                { title:"Network Security",    questions:[], sum:0, edit:false },
                { title:"SAAS Security",       questions:[], sum:0, edit:false },
            ]
        },
        generateQuestion:{},
        field:null,
        section:null,
        newQuestion:null,
        required:"required",
        isHovered:[],
        questionIsEdit:false,
        tabIndex:0,
        isEdit:false
    }
  },
  methods:{
    // formSubmitted() {
    //   this.$toast({
    //     component: ToastificationContent,
    //     props: {
    //       title: 'Form Submitted',
    //       icon: 'EditIcon',
    //       variant: 'success',
    //     },
    //   })
    // },
    changeIndex(value){
        this.tabIndex+=value;
        window.scrollTo({ top: 200, behavior: 'smooth' });
    },
    addQuestion(index, template){
        // console.log("add question", this.form.pages[index].title);
        let form = this.form.pages[index];
        // let tempQunewQuestionestion = JSON.parse(JSON.stringify(template))
        let tempOption = JSON.parse(JSON.stringify(this.multipleChoiceOption))
        //TODO find something else for key
        tempQuestion.key= `${form.questions.length}${tempQuestion.type}`
        tempQuestion.options.push(tempOption)
        // console.log("key", tempQuestion.key)


        form.questions.push(tempQuestion)
    },
    addOption(question){
        let tempOption = JSON.parse(JSON.stringify(this.multipleChoiceOption))
        tempOption.value = question.options.length+1;
        question.options.push(tempOption);
    },
    addSection(){
        if(this.section)
            this.form.pages.push({title:this.section, questions:[], sum:0, edit:false})
        this.section = null;
    },
    deleteOption(question, optionIndex){
        // console.log("question", question, "optionIndex", optionIndex);
        question.options.splice(optionIndex, 1);
    },
    saveQuestion(question){
        this.$refs.simpleRules.validate().then(success => { 
            if(success){
                // console.log("ref", this.tabIndex)

                question.answer = null;
                if(this.questionIsEdit) this.form.pages[this.tabIndex].questions[this.questionIsEdit.index] = JSON.parse(JSON.stringify(question))
                else this.form.pages[this.tabIndex].questions.push(JSON.parse(JSON.stringify(question)))

                this.questionIsEdit = false;
                this.closeModal()
                
                question.recommendedAnswer = null
                question.type = null
                question.recommendedAnswerText = null
            }
            else console.log("nope")
            
        })
    },
    closeModal(){
        // console.log("close modal")
        this.newQuestion = null;
        // this.generateQuestion = null;
        this.$bvModal.hide("addQuestion")
        this.questionIsEdit = false;
        this.isEdit = false;
    },
    editQuestion(index){
        // console.log("index", index)
        this.questionIsEdit = {index};
        let temp = JSON.parse(JSON.stringify(this.form.pages[this.tabIndex].questions[index]))
        // console.log("asd", this.form.pages[this.tabIndex].questions, "index", index)
        this.newQuestion = {name:temp.type, value:temp.type};
        this.generateQuestion = temp;
        this.isEdit = true;
        this.$bvModal.show("addQuestion")
    },
    deleteQuestion(index){
        this.form.pages[this.tabIndex].questions.splice(index,1)
    },
    deleteSection(){
        let index = this.tabIndex;
        this.form.pages.splice(index, 1);
        // console.log('tab index', this.tabIndex)
        if (this.tabIndex) this.tabIndex -=1;
    },
    createQuestionnaire(){
        let error = null

        this.form.pages.forEach(e=>{
            if(!e.questions.length) {
                error = `One or more sections are empty. Please add some questions or delete the empty section and try again`
            }
        })

        if(!this.form.name){
            error = "Form Name is empty"
        }
        if(error){
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: `Some error occured`,
                    variant: 'danger',
                    text: error,
                },
            })
            return;
        }

        AxiosService.post("/questionnaireCreate", {
            name:this.form.name,
            form:JSON.stringify(this.form.pages)
        }).then(({data, err})=>{
            if(data) {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: data,
                        variant: 'success'
                    },
                })
                this.$router.push("/questionnaire")
            }
        })
        

    },
    selectTab(tab, index){
        this.tabIndex = index;
    },
    editTab(tab){
        let index = this.tabIndex;
        tab.edit=true;
        this.tabIndex = index;
    },
    doneEditing(tab){
        tab.edit = false;
        // console.log("this.tabIndex", this.tabIndex);
        if(document.getElementById(`tab_${this.tabIndex}`))
        document.getElementById(`tab_${this.tabIndex}`).classList.add("active")
    },
    isActive(tab){
        if(tab==this.tabIndex) return "active"
        return "";
    }

  },
  mounted(){
    this.tabIndex = 0;
    // document.getElementById(`tab_${0}`).classList.add("active")
  },
  watch: {
        tabIndex(newIndex, oldIndex){
            // console.log("tab selected", newIndex);
            if(document.getElementById(`tab_${oldIndex}`) && document.getElementById(`tab_${oldIndex}`).classList )
                document.getElementById(`tab_${oldIndex}`).classList.remove("active")
            
        if(document.getElementById(`tab_${newIndex}`))
                document.getElementById(`tab_${newIndex}`).classList.add("active")
            this.tabIndex = newIndex;
        },
        // form(){
        //     if(this.form && this.form.pages){
        //         
        //     }
        // },
        newQuestion(questionType){
            // console.log("jere", questionType)
            if(this.questionIsEdit || !questionType) return;

            let tempQuestion = null;
            if(questionType && questionType.value=="MultipleChoice") {
                tempQuestion = JSON.parse(JSON.stringify(this.multipleChoiceQuestionTemplate))
                // let tempOption = JSON.parse(JSON.stringify(this.multipleChoiceOption))
                //TODO find something else for key
                // tempQuestion.key= `${form.questions.length}${tempQuestion.type}`
                tempQuestion.options = [];
                tempQuestion.options.push(JSON.parse(JSON.stringify(this.multipleChoiceOption)))
                tempQuestion.options.push(JSON.parse(JSON.stringify(this.multipleChoiceOption)))
                tempQuestion.options.push(JSON.parse(JSON.stringify(this.multipleChoiceOption)))
                tempQuestion.options.push(JSON.parse(JSON.stringify(this.multipleChoiceOption)))

                // this.generateQuestion = JSON.parse(JSON.stringify(tempQuestion))
            }
            else if(questionType && (questionType.value=="Dropdown" || questionType.value=="DropdownMultiple")) {
                tempQuestion = JSON.parse(JSON.stringify(this.dropDownQuestionTemplate))
                // let tempOption = 
                if(questionType.value=="DropdownMultiple")
                    tempQuestion.type="DropdownMultiple"
                //TODO find something else for key
                // tempQuestion.key= `${form.questions.length}${tempQuestion.type}`
                tempQuestion.options = [];
                tempQuestion.options.push(JSON.parse(JSON.stringify(this.dropDownOptionsTemplate)))
                tempQuestion.options.push(JSON.parse(JSON.stringify(this.dropDownOptionsTemplate)))
                tempQuestion.options.push(JSON.parse(JSON.stringify(this.dropDownOptionsTemplate)))
                tempQuestion.options.push(JSON.parse(JSON.stringify(this.dropDownOptionsTemplate)))

                
            }
            else if(questionType && questionType.value=="Text") {
                tempQuestion = JSON.parse(JSON.stringify(this.textQuestionTemplate))
                //TODO find something else for key
                // tempQuestion.key= `${form.questions.length}${tempQuestion.type}`

                // this.generateQuestion = JSON.parse(JSON.stringify(tempQuestion))
            }

            else if(questionType && questionType.value=="Slider") {
                tempQuestion = JSON.parse(JSON.stringify(this.sliderQuestionTemplate))
                //TODO find something else for key
                // tempQuestion.key= `${form.questions.length}${tempQuestion.type}`

                // this.generateQuestion = JSON.parse(JSON.stringify(tempQuestion))
            }

            if(tempQuestion && tempQuestion.options) {
                let optionValue = 1;
                tempQuestion.options.forEach(e=>{
                    e.value = optionValue++;
                })
            }

            this.generateQuestion = JSON.parse(JSON.stringify(tempQuestion))
        }
    },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
    .wizard-nav{
        display:none;
    }
    .add-option-btn{
        display:none
    }
    .question:hover .add-option-btn{
        display:block
    }
    .bottom-separation{
        border-bottom: 1px solid rgba(34, 41, 47, 0.08);
        margin: 0 -20px 10px;
        border-color: #3b4253;
    }
    #addQuestion{
        border-radius: 5px;
    }
    .edit-question{
        display:none;
        position: absolute;
        right: 4em;
        z-index:99;
        padding:18px 0;
    }
    .question-list:hover .edit-question{
        display:block;
        
    }
    .editTab{
        display:none;
    }
    .nav-link.active .editTab{
        display:block;
    }
</style>